(function($){

	
	
	
	$('.DownNav').click(function() {
			$('.Nav-Mobile').slideToggle( "slow", function() {
		});
	});

	$('.navclose').click(function() {
		$('.Nav-Mobile').slideToggle( "slow", function() {
		});
	});

	$('[data-fancybox="gallery"]').fancybox({
		// Options will go here
	});
	

	$('.slick-3').slick({
		//dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
		centerPadding: '60px',
		centerMode: true,
		responsive: [
			{
				
				breakpoint: 768,
				slidesToScroll: 1,
				settings: {
					slidesToShow: 4
				}
				},
			
			{
				
				breakpoint: 580,
				settings: {
				arrows: false,
				slidesToShow: 1
				}
			}
		]
	});
	var rellax = new Rellax('.rellax', {
		callback: function(position){

		}
	});

	$("input[name='phone']").mask('+5600000000000', {placeholder: "Juro que no llamaremos tarde..."});

}(jQuery));


$(window).scroll(function() {
		/*if($(this).scrollTop() > 40) 
		{
				$('header').addClass('c-HeaderFixed');
		} else {
				$('header').removeClass('c-HeaderFixed');
		}  */
		
		
});

/*$(window).resize(function(){

	
	if ($(window).width() >= 580) {  
		//alert('hola');

		$('.Mobile-nav').addClass('HideNav');     
	}     
});*/
